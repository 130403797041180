import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useContext } from "react";
import { VideoContext } from "./wrapper";
import { PlayCircleOutline } from "@mui/icons-material";

function PreviousVideos() {
  const videosContext = useContext(VideoContext);
  const { videos, setActiveVideo, activeVideo } = videosContext;

  return (
    videos.length > 0 && (
      <Box
        sx={{
          maxHeight: {
            xs: "auto",
            lg: "calc(100vh - 70px)",
          },
          overflowY: "auto",
        }}
      >
        <Typography variant="h6" sx={{ display: { lg: "none", my: 3 } }}>
          Previous Videos
        </Typography>
        {videos.map((video) => (
          <>
            <Box
              component="button"
              onClick={() => setActiveVideo(video)}
              sx={{
                border: activeVideo === video ? "1px solid white" : "0px",
                bgcolor: "transparent",
              }}
            >
              <Typography sx={{ color: "text.primary" }}>
                {video.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {new Date().getTime() < new Date(video.publishDate).getTime()
                  ? `Scheduled ${new Date(video.date).toLocaleDateString()}`
                  : new Date(video.date).toLocaleDateString()}
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={video.thumbnail}
                  alt="Video Thumbnail"
                  style={{
                    width: "95%", // Make the image fill the container
                    height: "auto",
                    margin: 0,
                    padding: 0,
                    objectFit: "cover", // Cover the area without stretching the image
                    opacity: 0.7,
                  }}
                />
                <IconButton
                  onClick={() => setActiveVideo(video)}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 500,
                  }}
                >
                  <PlayCircleOutline
                    sx={{ color: "rgba(255,255,255,.7)", fontSize: "50px" }}
                  />
                </IconButton>
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />
          </>
        ))}
      </Box>
    )
  );
}

export default PreviousVideos;
