import React, { useContext, useState } from "react";
import { VideoContext } from "./wrapper";
import {
  Alert,
  Box,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from "@mui/material";
import { Chat } from "@mui/icons-material";
import axios from "axios";
import { useMsal } from "@azure/msal-react";

function Comments() {
  const videoContext = useContext(VideoContext);
  const { activeVideo, setActiveVideo } = videoContext;
  const [comment, setComment] = useState({
    text: "",
  });

  const { accounts } = useMsal();

  let user = accounts[0]?.name;

  const addComment = async () => {
    let data = {
      text: comment.text,
      date: new Date().getTime(),
      user: user,
    };

    try {
      await axios.post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/updateWithKey?containerId=stream&id=${activeVideo.id}&partitionKey=id`,
        { comments: [data, ...activeVideo.comments] }
      );
      setComment({
        text: "",
      });
      setActiveVideo({
        ...activeVideo,
        comments: [data, ...activeVideo.comments],
      });
    } catch (err) {
      alert("There was an error saving comment");
    }
  };

  return (
    <Box
      sx={{
        height: {
          xs: "auto",
          lg: "calc(100vh - 80px)",
        },
        border: "1px solid #0b56a4",
        borderRadius: 2,
        p: 2,
        overflowY: "auto",
      }}
    >
      <Typography sx={{ color: "text.secondary" }}>Comments</Typography>

      <Alert severity="info" icon={<Chat />}>
        {activeVideo?.prompt ? activeVideo.prompt : "Leave your comments"}
      </Alert>
      <TextField
        variant="outlined"
        multiline
        minRows={3}
        fullWidth
        sx={{ my: 2 }}
        onChange={(e) =>
          setComment({
            text: e.target.value,
          })
        }
        placeholder="Write your comment here"
      />
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          disableElevation
          size="small"
          sx={{ textTransform: "capitalize" }}
          onClick={addComment}
        >
          Post comment
        </Button>
      </Box>

      <List sx={{ width: "100%" }}>
        {activeVideo.comments?.map((comment) => (
          <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={comment.user} src="/" />
            </ListItemAvatar>
            <ListItemText
              primary={comment.user}
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline" }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    {new Date(comment.date).toLocaleDateString()}
                  </Typography>
                  {` — ${comment.text}`}
                </React.Fragment>
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Comments;
