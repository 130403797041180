import React, { useEffect} from "react";
import {
    useMsal,
    useMsalAuthentication,
  } from "@azure/msal-react";
  import { InteractionType } from "@azure/msal-browser";
  import { useNavigate } from 'react-router-dom';

function Login() {

    const navigate = useNavigate();
    const { login, result, error } = useMsalAuthentication(
      InteractionType.Redirect
    );
    const { accounts } = useMsal();

    console.log(accounts)
  
    useEffect(() => {
      if (result && result.account) {
        navigate("/videos");
      } else if (accounts.length === 0) {
        navigate("/");
      }
    }, [result]);


    return <></>
}

export default Login;