import React, { useContext } from "react";
import { VideoContext } from "./wrapper";
import { Typography } from "@mui/material";

function LatestVideo() {
  const videoContext = useContext(VideoContext);
  const { videos, activeVideo } = videoContext;

  return (
    videos.length > 0 && (
      <>
        <Typography variant="h5" sx={{ color: "text.primary" }}>
          {activeVideo.name}
        </Typography>
        <Typography sx={{ mb: 2, color: "text.secondary" }}>
          {new Date(activeVideo.date).toLocaleDateString()}
        </Typography>
        <div
          dangerouslySetInnerHTML={{ __html: activeVideo?.iframe }}
          style={{ width: "100%" }}
        />
      </>
    )
  );
}

export default LatestVideo;
