import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import LatestVideo from "./latestVideo";
import { Grid } from "@mui/material";
import PageLayout, {
  UploadContext,
} from "../../components/pageLayout/pageLayout";
import PreviousVideos from "./previousVideos";
import Comments from "./comments";
import { useMsal } from "@azure/msal-react";

export const VideoContext = createContext({
  videos: [],
  setVideos: () => {},
});

let uploadEmails = [
  "carters@transblue.org",
  "jim.wescott@transblue.org",
  "jim.wescott@evergreenbrands.com",
  "ralhel@transblue.org",
  "ralph.helm@evergreenbrands.com",
];

function VideoWrapper() {
  const [videos, setVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState({});
  const today = new Date().getTime();
  const { accounts } = useMsal();
  const account = accounts[0];
  const username = account.username?.toLowerCase();

  const admin = Boolean(uploadEmails.includes(username?.toLowerCase()));
  console.log("admin", admin);

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=stream`
      )
      .then((res) => {
        let sortedArray = res.data.sort((a, b) => {
          if (a.date < b.date) return 1;
          if (a.date > b.date) return -1;
          return 0;
        });

        if (!admin) {
          sortedArray = sortedArray.filter(
            (v) => !v.publishDate || new Date(v.publishDate).getTime() <= today
          );
        }

        console.log("sortedArray: ", sortedArray);
        setVideos(sortedArray);
        setActiveVideo(sortedArray[0]);
      });
  }, []);

  return (
    <PageLayout>
      <VideoContext.Provider value={{ videos, activeVideo, setActiveVideo }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2} order={{ xs: 3, lg: 1 }}>
            <PreviousVideos />
          </Grid>
          <Grid item xs={12} lg={7} order={{ xs: 1, lg: 2 }}>
            <LatestVideo />
          </Grid>
          <Grid item xs={12} lg={3} order={{ xs: 2, lg: 3 }}>
            <Comments />
          </Grid>
        </Grid>
      </VideoContext.Provider>
    </PageLayout>
  );
}

export default VideoWrapper;
