import { Routes, Route } from "react-router-dom";
import Login from "./pages/login/login";
import SignedOut from "./pages/login/signedOut";
import { useClearCache } from "react-clear-cache";
import { useEffect } from "react";
import Upload from "./pages/upload/upload";
import VideoWrapper from "./pages/videos/wrapper";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import { useNavigate } from "react-router-dom";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

function App() {
  const navigate = useNavigate();
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  const { accounts } = useMsal();
  console.log(accounts)

  useEffect(() => {
    if(accounts.length === 0 && window.location.pathname!== '/' && window.location.pathname !== '/signin') {
      navigate('/')
    }
  }, [accounts])

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline enableColorScheme />
      <AuthenticatedTemplate>
        <Routes>
          <Route exact path="/videos" element={<VideoWrapper />} />
          <Route exact path="/videos/:id" element={<VideoWrapper />} />
          <Route exact path="/upload" element={<Upload />} />
          <Route exact path='/' element={<SignedOut />} />
        </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Routes>
          <Route exact path='/' element={<SignedOut />} />
          <Route exact path='/signin' element={<Login />} />
        </Routes>
      </UnauthenticatedTemplate>
    </ThemeProvider>
  );
}

export default App;
